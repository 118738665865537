import { pickRandomItem, QUICK_COLORS } from "shared";
import { TooltipCorrectionPriority } from "./tooltip-correction-util";
import { WebDialog } from "web-dialog";

export function isMobile() {
	return window.innerWidth <= 800;
}

export function isTouch() {
	// return !hasFinePointer();
	return window.matchMedia("(any-hover: none)").matches;
}

/**
 * Matches the media query @media (pointer: fine)
 */
export function hasFinePointer() {
	return window.matchMedia("(pointer: fine)").matches;
}

/**
 * Selects all text in an input
 * @param $input
 */
export function selectTextInInput($input: HTMLInputElement | HTMLTextAreaElement) {
	$input.focus();
	requestAnimationFrame(() => {
		$input.setSelectionRange(0, $input.value.length);
	});
}

export function trimText(text: string, length: number) {
	if (text.length <= length) return text;
	return `${text.slice(0, length)}...`;
}

export function isEmoji(text: string) {
	return /^[/\p{Emoji}|❤️]+$/u.test(text);
}

export function getInitials(name: string, maxLettersCount = 2) {
	return name
		.split(" ")
		.slice(0, maxLettersCount)
		.map((word) => word[0])
		.join("")
		.toUpperCase();
}

export function stopEvent(e: Event) {
	e.preventDefault();
	e.stopPropagation();
}

export function getRandomHexColor() {
	return pickRandomItem(QUICK_COLORS);
}

export function redispatchEvent($target: EventTarget, e: Event | CustomEvent, type = e.type) {
	stopEvent(e);
	$target.dispatchEvent(
		new CustomEvent(type, {
			bubbles: e.bubbles,
			cancelable: e.cancelable,
			composed: e.composed,
			detail: "detail" in e ? e.detail : undefined
		})
	);
}

/**
 * Waits x ms.
 * @param ms
 */
export function wait(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Promisifies an animation.
 * @param animation
 */
export function promisifyAnimation(animation: Animation): Promise<AnimationPlaybackEvent> {
	return new Promise((res, rej) => {
		animation.onfinish = ((e: AnimationPlaybackEvent) => res(e)) as any;
		animation.oncancel = rej;
	});
}

export function roundToNearest(number: number, nearest = 10) {
	return Math.floor(number / nearest) * nearest;
}

/**
 * Rounds a number to 2 decimals.
 * https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
 * @param num
 */
export function round(num: number) {
	return Math.round(num * 100) / 100;
}

export function leadingZero(value: number, length = 2) {
	return value.toString().padStart(length, "0");
}

export function isNumber(value: string | number): boolean {
	return !isNaN(Number(value.toString()));
}

/**
 * Capitalizes the first letter.
 * @param string
 */
export function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Attaches an outside click listener.
 * @param $elem
 * @param isActive
 * @param cb
 * @param capture
 * @param prevent
 */
export function attachOutsideClickListener({
	$elem,
	isActive,
	cb,
	capture = true,
	prevent = true
}: {
	$elem: HTMLElement;
	isActive: () => boolean;
	cb: (e: MouseEvent, didClickSameElementType: boolean) => void;
	capture?: boolean;
	prevent?: boolean;
}) {
	const listener = (e: MouseEvent) => {
		if (isActive() && !e.composedPath().includes($elem)) {
			// If another element with the same tagname is clicked, allow it to open immediately by not preventing the event.
			// This allows the user to for example click on many menu dropdowns without having to close the current one first.
			const didClickSameElementType = e
				.composedPath()
				.map((elem) => (elem as HTMLElement).tagName)
				.includes($elem.tagName);

			if (prevent && !didClickSameElementType) {
				e.preventDefault();
				e.stopPropagation();
			}

			cb(e, didClickSameElementType);
		}
	};

	const removeListener = () => window.removeEventListener("pointerdown", listener, { capture });

	window.addEventListener("pointerdown", listener, { capture });
	return removeListener;
}

/**
 * Creates a range of numbers.
 * @param from
 * @param to
 */
export function createRange(from: number, to: number) {
	return Array.from({ length: to - from + 1 }, (_, i) => from + i);
}

export function removeUndefinedProperties<T extends Record<string, any>>(obj: T): T {
	const newObj: any = {};
	for (const key in obj) {
		if (obj[key] !== undefined) newObj[key] = obj[key];
	}
	return newObj;
}

export function waitForNextAnimationFrame() {
	return new Promise((resolve) => requestAnimationFrame(resolve));
}

export interface BoundsResult {
	isWithinBounds: boolean;
	availableSize: { height: number; width: number };
}

/**
 * Returns true if the element is within the bounds.
 * !!! Expensive to run !!!
 * @param $elem
 * @param justify
 * @param align
 * @param padding
 * @param $container
 */
export function checkBounds(
	$elem: HTMLElement,
	{
		justify,
		align,
		padding,
		$container
	}: TooltipCorrectionPriority & { padding?: number; $container?: HTMLElement | Window }
): BoundsResult {
	// Find the bounds
	const {
		left: containerLeft,
		top: containerTop,
		right: containerRight,
		bottom: containerBottom
	} = $container == null || $container == window
		? {
				left: 0,
				top: 0,
				right: window.innerWidth,
				bottom: window.innerHeight
			}
		: ($container as HTMLElement).getBoundingClientRect();

	const rect = $elem.getBoundingClientRect();

	const isWithinBounds =
		rect.left >= containerLeft &&
		rect.top >= containerTop &&
		rect.right <= containerRight &&
		rect.bottom <= containerBottom;

	const availableSize = {
		height:
			(align === "top" ? containerTop : containerBottom) - (align === "top" ? -rect.bottom : rect.top) - (padding ?? 0),
		width:
			(justify === "left" ? containerLeft : containerRight) -
			(justify === "left" ? -rect.right : rect.left) -
			(padding ?? 0)
	};

	return {
		isWithinBounds,
		availableSize
	};
}

/**
 * Finds the first scrollable parent (also pierces through shadow roots).
 * @param $elem
 */
export function findFirstScrollableParent($elem?: HTMLElement | null): HTMLElement | Window {
	if ($elem == null) return window;
	if ($elem.scrollHeight > $elem.clientHeight) return $elem;
	return findFirstScrollableParent($elem.parentElement ?? ($elem.getRootNode() as any)?.host);
}

/**
 * Closes all webdialogs.
 */
export function closeAllDialogs() {
	const $dialogs = document.querySelectorAll("web-dialog");
	$dialogs.forEach(($dialog) => ($dialog as WebDialog).close());
}

/**
 * Returns the height of the app and is iOS safe.
 */
export function getAppHeight() {
	return Math.min(window.innerHeight, window.visualViewport?.height ?? Infinity);
}
